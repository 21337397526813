<!-- ResetPassword.vue -->
<template>
    <div class="reset-password-cont">
      <!-- <h2>Reset Password</h2> -->
      <form class="reset-form" @submit.prevent="resetPassword">

        <div class="form-group">
            <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="newPassword"> New Password</label>
            <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter new Password" id="newPassword" type="password" v-model="v$.form.newPassword.$model">
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.newPassword.$errors" :key="index">
                <div class="error-msg g-font">{{ error.$message }}</div>
            </div>
        </div>

        <div class="form-group">
            <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="confirmPassword"> Confirm Password</label>
            <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Re-enter new Password" id="confirmPassword" type="password" v-model="v$.form.confirmPassword.$model">
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.confirmPassword.$errors" :key="index">
                <div class="error-msg g-font">{{ error.$message }}</div>
            </div>
        </div>

        <!-- <label for="newPassword">New Password:</label>
        <input type="password" id="newPassword" v-model="newPassword" required />
  
        <label for="confirmPassword">Confirm Password:</label>
        <input type="password" id="confirmPassword" v-model="confirmPassword" required /> -->
  
        <div v-if="errorMsg" class="error-msg on-center">{{ errorMsg }}</div>
        <button type="submit" class="submit-button">Reset Password</button>

        <!-- <button type="submit">Reset Password</button> -->
      </form>
    </div>
  </template>
  
  <script>
  import useVuelidate from '@vuelidate/core'
  import { required, minLength } from '@vuelidate/validators'
  import { getMode } from "@/services/localStorage";
  const checkIfDark = (name) => name === "dark-mode";
  export default {
    name: 'resetPassword',
    setup () {
    return { v$: useVuelidate() }
  },
    data() {
      return {
        darkMode: checkIfDark(getMode()),
        form: {
            newPassword: '',
            confirmPassword: '',
        },
        errorMsg: "",
      };
    },
    created() {
    this.emitter.on("mode-change", (data) => {
      this.darkMode = checkIfDark(data.mode);
    });
  },
    methods: {
      resetPassword() {
        // Perform reset password logic
        if (this.form.newPassword === this.form.confirmPassword) {
          // Reset password API call or logic goes here
          this.errorMsg = "Password reset successful!";
          console.log('Password reset successful!');
        } else {
          this.errorMsg = "Passwords do not match.";
          console.error('Passwords do not match.');
        }
      },
    },
    validations() {
    return {
      form: {
        newPassword: { required, min: minLength(10) },
        confirmPassword: { required, min: minLength(10) },
      },
    }
  },
  };
  </script>
  
  <style scoped>
  /* Add your component-specific styles here */
  

  .reset-password-cont {
    width: 30%;
    height: 50%;;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #242460;
    flex-direction: column;
    border-radius: 5px;
    margin-top: 20px;
  }
  .form-group {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    width: 100%;
    }

    .reset-form {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-control {
        border: 1px solid #353580;
        background: transparent;
        border-radius: 4px;
        color: white;
    }

    label {
    display: block;
    margin-bottom: 5px;
    }

    input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    }

    .error-msg {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
    margin-bottom: 5px;
    }
    .g-font {
        font-family: "Lato", sans-serif;
    }
    .submit-button {
        color: white;
        padding: 10px;
        border: none;
        cursor: pointer;
        width: 100%;
        font-size: 1em;
        border-radius: 4px;
        background-color: #242460;
        margin-top: 20px;

    }
    .on-center {
        display: flex;
        justify-content: center;
    }
    .gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}
.dark-placeholder::placeholder {
  color: #242460; /* Dark mode placeholder color */
}
  </style>
  