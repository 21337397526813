<template>
  <footer class="app-footer">
    <p>&copy; 2023 Autom8</p>
    <div class="icons">
      <img src="@/assets/icons/twitter.svg" alt="" />
      <img src="@/assets/icons/facebook.svg" alt="" />
    </div>
    <router-link class="anchor" :to="'/privacy/'">Privacy Policy</router-link>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped lang="scss">
footer {
  background-color: transparent;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}

.app-footer p {
  margin: 0;
  font-size: 14px;
}

img {
  height: 25px;
}

.anchor {
  margin: 0 10px 3px;
}

.icons {
  margin-left: 20px;

  img {
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      transition: ease-in-out 0.2s;
      transform: scale(1.2);
    }
  }
}
</style>
