const MODE_KEY = "appMode";
const USER_KEY = "appUser";

// Function to set the mode in localStorage
export const setMode = (mode) => {
  localStorage.setItem(MODE_KEY, mode);
};

// Function to get the mode from localStorage
export const getMode = () => {
  return localStorage.getItem(MODE_KEY) || "dark-mode";
};

// Function to set the user in localStorage
export const setUser = (user) => {
  localStorage.setItem(USER_KEY, user);
};

// Function to get the user from localStorage
export const getUser = () => {
  return localStorage.getItem(USER_KEY) || "";
};

export const setToken = (token) => {
  localStorage.removeItem("token");
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  return localStorage.removeItem("token");
};

export const removeUserObject = () => {
  return localStorage.removeItem("user");
};

export const setUserObject = (user) => {
  let { full_name, email } = user;
  const userData = { full_name, email };
  localStorage.removeItem("user");
  localStorage.setItem("user", JSON.stringify(userData));
};

export const setAccountObj = (user) => {
  localStorage.removeItem("user");
  localStorage.setItem("user", JSON.stringify(user));
};


export const getUserObject = () => {
  const user = localStorage.getItem("user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  } else {
    return null;
  }
};
