<template>
  <div class="page">
    <div class="left">
      <div class="inner-box">
        <DynamicTable
          @rowSelected="rowSelected"
          @rowDeleted="rowDeleted"
          :showDelete="true"
          :showFooter="false"
          :isLoading="loading"
          :columns="tableColumns"
          :data="tableData"
        />
      </div>
    </div>
    <div class="right">
      <div></div>
      <div>
        <div class="unit">
          <label for="">Platform</label>
          <select v-model="platform.platform" class="select">
            <option value="" disabled>Select Platform</option>
            <option value="Taichi">Taichi</option>
            <option value="VBlink">VBlink</option>
            <option value="Orionstar">Orionstar</option>
            <option value="Firekirin">Firekirin</option>
            <option value="Acebook">Acebook</option>
            <option value="GameVault999">GameVault999</option>
            <option value="Juwa">Juwa</option>
            <option value="Milkyway">Milkyway</option>
            <option value="BlueDragon">BlueDragon</option>
            <option value="GoldenDragon">GoldenDragon</option>
            <option value="DragonCrown">DragonCrown</option>
            <option value="BigWinner">BigWinner</option>
            <option value="UltraPanda">UltraPanda</option>
            <option value="DragonWorld">DragonWorld</option>
            <option value="PandaMaster">PandaMaster</option>
            <option value="CandyLand">CandyLand</option>
            <option value="RiverSweep">RiverSweep</option>
            <option value="Yolo">Yolo</option>
            <option value="Egame">Egame</option>
            <option value="CashMachine">CashMachine</option>
            <option value="GameRoom">GameRoom</option>
              

          </select>
        </div>
        <div class="unit">
          <label for="">Username</label>
          <GInput
            v-model:value="platform.username"
            :label="'Username'"
          ></GInput>
        </div>
        <div class="unit">
          <label for="">Password</label>
          <GInput
            v-model:value="platform.password"
            :label="'Password'" type="password"
          ></GInput>
        </div>
        <div v-if="platform.platform === 'GoldenDragon'" class="unit">
          <label for="">URL Key</label>
          <GInput
            v-model:value="platform.url_key"
            :label="'URL Key'"
          ></GInput>
        </div>
      </div>
      <div class="foot">
        <label class="errorMsg">{{ errorMsg }}</label>
        <Button @click="addPlatform()">ADD</Button>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicTable from "@/components/Table.vue";
import Button from "@/components/Button.vue";
import GInput from "@/components/Input.vue";
import { get, post, del } from "@/services/http";
import {
  PLATFORMS,
  CREATE_PLATFORM,
  DELETE_PLATFORM,
} from "@/utils/api-routes";

export default {
  name: "UserList",
  components: {
    DynamicTable,
    Button,
    GInput,
  },
  data() {
    return {
      tableColumns: [
        { key: "name", label: "Platform", type: "title" },
        { key: "username", label: "Username" },
        { key: "password", label: "Password" },
        { key: "url_key", label: "Url Key" },
      ],
      tableData: [],
      loading: false,
      platform: {
        platform: "",
        username: "",
        password: "",
        url_key: "",
      },
      errorMsg: "",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    validateFields(fields) {
      if (!fields.platform || fields.platform.length === 0) {
        throw new Error("Platform name is required.");
      }

      if (!fields.username || fields.username.length === 0) {
        throw new Error("Username is required.");
      }

      if (!fields.password || fields.password.length === 0) {
        throw new Error("Password is required.");
      }

      // if (fields.password.length < 8) {
      //   throw new Error("Password should be greater than 8 letters.");
      // }

      return true; // Fields are valid if they pass all checks
    },

    
    async fetchData() {
      try {
        this.loading = true;
        const { Data } = await get(
          PLATFORMS
        );
        this.tableData = Data;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching platforms:", error);
      }
    },

    async addPlatform() {
      try {
        this.validateFields(this.platform);
        const { platform, username, password, url_key } = this.platform;
        let queryString = `?platform=${platform}&username=${username}&password=${password}`;

        if (platform === 'GoldenDragon') {
          queryString += `&url_key=${url_key}`;
        }

        const finalUrl = CREATE_PLATFORM + queryString;
        const { Status } = await post(finalUrl);
        if (Status === "Created") {
          this.fetchData();
          this.platform = {
            platform: "",
            username: "",
            password: "",
            url_key: "",
          };
        }
      } catch (error) {
        this.errorMsg = "* " + error?.toString().replace("Error: ", "");
        setTimeout(() => {
          this.errorMsg = "";
        }, 5000);
        console.log("Error adding platform:", error);
      }
    },

    rowSelected(data) {
      const { name, username, password, url_key } = data;
      this.platform = {
        platform: name,
        username,
        password,
      };
      if (name === 'goldenDragon') {
        this.platform.url_key = url_key;
      }
    },

    async rowDeleted(data) {
      try {
        const { id } = data;
        console.log(data);
        this.loading = true;
        const { Status } = await del(
          DELETE_PLATFORM + id
        );
        this.loading = false;
        if (Status == "OK") {
          this.fetchData();
          
        }
      } catch (error) {
        console.log("Error deleting platform:", error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  display: flex;
  align-items: flex-start;
  margin: 20px 0px 20px 0px;

  .left {
    width: 48vw;
  }

  .right {
    background: rgba(255, 255, 255, 0.0784313725);
    width: calc(48vw - 100px);
    margin-left: 50px;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .foot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .errorMsg {
      font-size: 22px;
      color: #ff8a96;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
  }

  .unit {
    display: flex;
    align-items: center;
    margin: 30px 0px;

    label {
      font-size: 25px;
      font-weight: 500;
      width: 200px;
    }
  }

  .select {
    padding: 10px;
    border: none;
    width: 300px;
    border-radius: 3px;
    color: #000000b0;

    &::placeholder {
      color: var(--input-placeholder);
      opacity: 0.3;
    }

    &:focus {
      outline: none;
      border-color: white;
    }
  }
}

@media (max-width: 768px) {
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    .left {
      width: 80vw;
      overflow: auto;
      .inner-box {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        min-width: 400px;
      }
    }
    .right {
      width: 72vw;
      margin-left: 0px;
      margin: 30px 0px 30px 0px;
    }
  }
  .unit {
    label {
      font-size: 15px !important;
    }
  }
}
</style>
