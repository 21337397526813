<template>
    <div v-if="isLoading && formSubmitted" class="loader-c">
        <div class="loader"></div>
        <span>Logging In...</span>
    </div>
    <form v-if="!formSubmitted" @submit.prevent="submitForm" class="signin-form">
      <!-- Email Row -->
      <div class="form-group">
        <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="email"> Username</label>
        <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter username" id="email" type="text" v-model="v$.form.email.$model">
        <!-- Error Message -->
          <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
            <div class="error-msg g-font">{{ error.$message }}</div>
          </div>
      </div>
  
      <!-- Password and Confirm Password Row -->
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="password"> Password</label>
            <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter Password" id="password" type="password" v-model="v$.form.password.$model">
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
              <div class="error-msg g-font">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Submit Button -->
      <div v-if="errorMsg" class="error-msg on-center">{{ errorMsg }}</div>
      <button type="submit" :disabled="formSubmitted || v$.form.$invalid" class="submit-button g-font">Log In</button>
    </form>
    <!-- <div class="reset-forget-cont">
      <router-link to="/forget-password" class="txt-cl g-font forget" :class="{'is-dark': darkMode}">Forget Password?</router-link>
    </div> -->
  </template>
  
<script>
import { getMode } from "@/services/localStorage";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { get } from "@/services/http";
import {
  LOGIN_ACCOUNT,
  GET_USER
} from "@/utils/api-routes";
import { setToken, setAccountObj } from "@/services/localStorage";

const checkIfDark = (name) => name === "dark-mode";


export default {
  name: 'SignIn',
  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      darkMode: checkIfDark(getMode()),
      form: {
        email: '',
        password: '',
      },
      errorMsg: "",
      formSubmitted: false,
      isLoading : true,
    }
  },
  created() {
    this.emitter.on("mode-change", (data) => {
      this.darkMode = checkIfDark(data.mode);
    });
  },

  validations() {
    return {
      form: {
        email: { required },
        password: { required },
      },
    }
  },
  methods: {
    async submitForm() {
        console.log('New Form submitted:', this.form.email, this.form.password);
        try {
        const response = await get(LOGIN_ACCOUNT + `?username=${this.form.email}&password=${this.form.password}`);
        console.log('token: ', response);
        if (response.access_token) {
          this.formSubmitted = true;
          setToken(response.access_token);
          const account = await get(GET_USER);
          console.log('account: ', account);
          setAccountObj(account);
          this.$router.push('/home');
        }
        } 
        catch (error) {
          const {
          response: { data },
        } = error;
          this.errorMsg = data.detail ? data.detail : "Something went wrong";
          console.log('errorMsg: ', this.errorMsg);
          console.error('Error signing in:', error);
        }
    },
  },
}

</script>
  
<style scoped>
.reset-forget-cont {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5vh;
}
.txt-cl {
  text-decoration: none;
  color: #242460;
  font-weight: bold;
}
.is-dark {
  text-decoration: none;
  font-weight: bold;
  color: #1c7cc9;
}

.g-font {
    font-family: "Lato", sans-serif;
 }
.form-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0px;
}

.loader-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -15vh;
}

.signin-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.dark-placeholder::placeholder {
  color: #242460; /* Dark mode placeholder color */
}

.form-control {
    border: 1px solid #353580;
    background: transparent;
    border-radius: 4px;
    color: white;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error-msg {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.on-center {
  display: flex;
  justify-content: center;
}

.submit-button {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  background-color: #242460;
}

.submit-button:disabled {
  /* background-color: rgb(0, 172, 193, 0.11); */
  background-color: #242460;
  pointer-events: none;
}
.submit-button:hover {
    background-color: rgba(255, 255, 255, 0.11);
}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}

@media (max-width: 568px) {
  .signin-form {
    margin-top: 55px;
  }
}
</style>
  
