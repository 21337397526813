<!-- ForgetPassword.vue -->
<template>
    <div class="forget-password-cont">
      <form class="forget-form" @submit.prevent="forgetPassword">
        <!-- forget password form fields go here -->
        <div class="form-group">
            <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="email"> Email address</label>
            <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter email" id="email" type="email" v-model="v$.form.email.$model">
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                <div class="error-msg g-font">{{ error.$message }}</div>
            </div>
        </div>
        <button type="submit" class="submit-button">Send Reset Password Link</button>
      </form>
    </div>
  </template>
  
  <script>
  import useVuelidate from '@vuelidate/core'
  import { required, email } from '@vuelidate/validators'
  import { getMode } from "@/services/localStorage";
  const checkIfDark = (name) => name === "dark-mode";

  export default {
    name: 'forgetPassword',
    setup () {
    return { v$: useVuelidate() }
    },
    data() {
    return {
      darkMode: checkIfDark(getMode()),
      form: {
        email: '',
      },
    }
  },
  created() {
    this.emitter.on("mode-change", (data) => {
      this.darkMode = checkIfDark(data.mode);
    });
  },

  validations() {
    return {
      form: {
        email: { required, email },
      },
    }
    },
    methods: {
        forgetPassword() {
        this.$router.push('/reset-password')
        // Handle forget password logic here
      },
    },
  };
  </script>
  <style scoped>

  .forget-password-cont {
    width: 30%;
    height: 40%;;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #242460;
    flex-direction: column;
    border-radius: 5px;
    margin-top: 20px;
  }
  .form-group {
    /* margin-bottom: 22px; */
    display: flex;
    flex-direction: column;
    width: 100%;
    }

.forget-form {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-control {
    border: 1px solid #353580;
    background: transparent;
    border-radius: 4px;
    color: white;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error-msg {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}
.g-font {
    font-family: "Lato", sans-serif;
 }
 .submit-button {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  background-color: #242460;
  margin-top: 20px;

}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}
.dark-placeholder::placeholder {
  color: #242460; /* Dark mode placeholder color */
}
  </style>
  