import { createApp } from "vue";
import App from "./App.vue";
import "./global.scss";
import router from "./router";
import mitt from "mitt";
import gAuthPlugin from "vue3-google-oauth2";

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.appMode = "dark-mode";

app.use(router);
let gauthClientId =
  "944633192903-9lc51cl372ook59ep3ek5tr1ime61216.apps.googleusercontent.com";
app.use(gAuthPlugin, {
  clientId: gauthClientId,
  scope: "email",
  prompt: "consent",
  plugin_name: "pryzer",
});

app.mount("#app");
