<template>
  <div :class="['dynamic-table', this.mode]">
    <div class="table-header">
      <div class="table-cell"></div>
      <div
        v-for="column in columns"
        :key="column.key"
        class="table-cell"
        @click="column?.sortable && sortByColumn(column.key)"
        :class="{ sortable: column.sortable, 'first-column': index === 0 }"
      >
        {{ column.label }}
        <span v-if="column.sortable">
          <img
            class="arrow"
            v-if="this.mode === 'dark-mode'"
            src="@/assets/arrow-up-white.svg"
            :class="[
              {
                'arrow-down':
                  page.attribute === column.key && sortOrder === 'asc',
              },
            ]"
            alt=""
          />
          <img
            v-else
            class="arrow"
            src="@/assets/arrow-up.svg"
            :class="[
              {
                'arrow-down':
                  page.attribute === column.key && sortOrder === 'asc',
              },
            ]"
            alt=""
          />
        </span>
      </div>
      <div v-if="showDelete" class="delete-cell"></div>
    </div>
    <div class="table-body">
      <div v-if="isLoading" class="loader-c">
        <div class="loader"></div>
      </div>
      <div v-else-if="data?.length === 0" class="loader-c">
        <h3>No Records Found</h3>
        <div>
          <img class="vibrating-element" src="@/assets/glass.svg" alt="" />
        </div>
      </div>
      <div
        v-else
        v-for="(item, index) in data"
        :key="item.id"
        @click="rowSelected(item)"
        class="table-row"
        :class="{ 'alternate-row': index % 2 === 1 }"
      >
        <div class="table-cell">
          {{ index + 1 }}
        </div>
        <div v-for="column in columns" :key="column.key" class="table-cell">
          <div v-if="column.type === 'status'" class="statuses">
            <div :class="['tag', item[column.key]?.toLowerCase() + '-tag']">
              <div>{{ item[column.key] }}</div>
              <img
                v-if="item[column.key] === 'Failed'"
                @click.stop="refresh(item)"
                title="Retry"
                height="20"
                src="@/assets/refresh.svg"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <span v-else-if="column.key === 'password'">
            {{ encryptPassword(item[column.key]) }}
          </span>
          <span v-else-if="column.type === 'date'">
            {{ formattedDate(item[column.key]) }}
          </span>
          <span v-else-if="column.key === 'username'">
            {{
              item[column.key].length < 4
                ? item[column.key].substring(0, 1) + "***"
                : item[column.key].substring(0, 4) + "***"
            }}
          </span>
          <span v-else>
            {{ item[column.key].length ? item[column.key] : "-" }}
          </span>
        </div>
        <div v-if="showDelete" class="delete-cell">
          <img
            @click.stop="rowDeleted(item)"
            src="@/assets/delete.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="pagination" v-if="showFooter">
      <div v-if="page?.total > 0" class="btns btn-box">
        <button @click="prevPage" :disabled="page?.start === 1">
          <img src="@/assets/arrow-left.svg" alt="Previous" />
        </button>
        <span class="count">
          Showing {{ page?.start || 0 }} of {{ page?.totalPages || 0 }} pages
          <span>(Total {{ page?.total || 0 }})</span>
        </span>
        <button @click="nextPage" :disabled="page.start == page.totalPages">
          <img class="rotate-img" src="@/assets/arrow-left.svg" alt="Next" />
        </button>
      </div>
      <div v-else></div>
      <div v-if="showFilters" class="filters">
        <div>
          <input
            type="radio"
            name="filter"
            id="successful"
            value="Successful"
            v-model="selectedFilter"
          />
          <label class="successful" for="successful">Successful</label>
        </div>
        <div>
          <input
            type="radio"
            name="filter"
            id="failed"
            value="Failed"
            v-model="selectedFilter"
          />
          <label class="failed" for="failed">Failed</label>
        </div>
        <div>
          <input
            type="radio"
            name="filter"
            id="skipped"
            value="Skipped"
            v-model="selectedFilter"
          />
          <label class="skipped" for="skipped">Skipped</label>
        </div>
        <div>
          <input
            type="radio"
            name="filter"
            id="all"
            value=""
            v-model="selectedFilter"
          />
          <label class="all" for="all">All</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMode } from "@/services/localStorage";

export default {
  name: "DynamicTable",
  props: {
    columns: Array,
    data: Array,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    pagination: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: "",
      sortOrder: "asc", // Default sort order
      selectedFilter: "",
      page: this.pagination,
    };
  },
  watch: {
    selectedFilter(data) {
      this.$emit("filter", data);
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.data.length / this.itemsPerPage);
    },
  },
  created() {
    this.mode = getMode();
    this.emitter.on("mode-change", (data) => {
      this.mode = data.mode;
    });
  },
  methods: {
    nextPage() {
      if (this.page.start < this.page.totalPages) {
        this.page.start++;
        this.$emit("pagination", this.page);
      }
    },
    prevPage() {
      if (this.page?.start > 1) {
        this.page.start--;
        this.$emit("pagination", this.page);
      }
    },
    sortByColumn(columnKey) {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.page.attribute = columnKey;
      this.page.order_by = this.sortOrder;
      this.$emit("pagination", this.page);
    },
    formattedDate(param) {
      const date = new Date(param);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    encryptPassword(password) {
      return password.replace(/./g, "*");
    },
    rowSelected(data) {
      this.$emit("rowSelected", data);
    },
    refresh(data) {
      this.$emit("refresh", data);
    },
    rowDeleted(data) {
      this.$emit("rowDeleted", data);
    },
  },
};
</script>

<style scoped lang="scss">
.arrow {
  height: 20px;
  margin: 5px 5px -3px 5px;
}

.arrow-down {
  transform: rotate(180deg);
}

.dynamic-table {
  display: flex;
  flex-direction: column;
  // margin: 50px;
  // min-width: 600px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.table-header {
  display: flex;
  background: linear-gradient(
    60deg,
    rgb(84, 58, 183) 0%,
    rgb(0, 172, 193) 100%
  );
  font-weight: bold;
  padding: 10px;

  .table-cell {
    font-size: larger;
    font-weight: bolder;
  }
}

.table-cell {
  padding: 10px;
  width: 200px;
  text-align: center;
  font-size: large;
  font-weight: 300;
  letter-spacing: 0.7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.delete-cell {
  width: 50px;
  display: flex;
  align-items: center;

  img {
    height: 30px;
    cursor: pointer;
  }
}

.table-cell:nth-child(1) {
  width: 30px;
  margin-left: 10px;
}

.statuses {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.table-body {
  display: flex;
  flex-direction: column;
  color: #29293e;
  height: 480px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  background: #ffffff14;
  padding: 15px 0;

  .table-row {
    &:hover {
      background: #ffffff26;
    }
  }
}

.table-row {
  display: flex;
  color: white;
}

.alternate-row {
  color: #f0f0f0;
}

.table-row,
.table-header {
  justify-content: space-between;
}

.dark-mode {
  .table-header {
    background: linear-gradient(60deg, #25274d 0%, rgb(68 45 153) 100%);
  }

  .pagination {
    .btns,
    button {
      color: black !important;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background: linear-gradient(
    60deg,
    rgba(255, 255, 255, 0.539) 0%,
    rgb(247, 247, 247) 100%
  );

  .btn-box {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-left: 284px;
  }

  .rotate-img {
    rotate: 180deg;
  }

  .count {
    color: black !important;
    margin-top: 2px;

    span {
      font-weight: bold;
    }
  }
}

.pagination button {
  padding: 5px 10px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}

.pagination button[disabled] {
  color: white;
  cursor: not-allowed;
}

.filters {
  display: flex;
  padding: 3px 5px;
  margin: 0 10px;
  border-radius: 5px;

  div {
    display: flex;
    align-items: center;
    margin: 0 5px;
    font-weight: 600;

    input {
      margin-right: 5px;
    }

    label,
    input {
      cursor: pointer;
    }
  }
}

.tag {
  padding: 3px 6px;
  border-radius: 4px;
  width: fit-content;
  text-transform: capitalize;
  min-width: 120px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    flex-grow: 1;
  }
  img {
    cursor: pointer;
  }
}

.new-tag {
  background-color: #0d6efd;
}

.successful-tag {
  background-color: #20c997;
}

.skipped-tag {
  background-color: #0d6efd;
}

.failed-tag {
  background-color: #87212b;
}

.new {
  color: #2288f5;
}

.successful {
  color: #28a745;
}

.skipped {
  color: #0d6efd;
}

.failed {
  color: #dc3545;
}

.all {
  background-image: linear-gradient(
    60deg,
    rgb(84, 58, 183) 0%,
    rgb(0, 172, 193) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Add styles for the sortable column headers */
.table-cell.sortable {
  cursor: pointer;
  user-select: none;
}

/* Style the arrow icon in the column header */
.table-cell span i {
  margin-left: 4px;
}

/* Style the arrow icon based on the sort order */
.table-cell span i.fa-arrow-up::before {
  content: "\f062";
  /* Up arrow icon */
}

.table-cell span i.fa-arrow-down::before {
  content: "\f063";
  /* Down arrow icon */
}

.loader-c {
  display: flex;
  flex-direction: column;

  h3 {
    color: white;
  }

  div {
    transform: rotate(180deg);
  }
}

.vibrating-element {
  animation: vibrate ease-in-out 1s infinite;
  height: 100px;
  opacity: 0.8;
}

@keyframes vibrate {
  0% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}
</style>
