<template>
    <button :class="['rounded-3xl mr-2', buttonType, buttonClass]" @click.prevent="handleClick">
        <img v-if="url || imgSrc" :class="['w-4 h-4 mr-2', imgClass]" :src="url ? url : require(`@/assets/${imgSrc}`)" />
        <slot></slot>
    </button>
    <!-- 
  fill-primary
  fill-secondary 
  transparent
  default
 -->
</template>

<script>
export default {
    name: "G-Button",
    props: {
        buttonClass: {
            type: String,
            default: "",
        },
        buttonType: {
            type: String,
            default: "fill-primary",
        },
        imgSrc: {
            type: String,
            default: "",
        },
        imgClass: {
            type: String,
            default: "",
        },
        url: {
            type: String,
            default: "",
        },
    },
    methods: {
        handleClick() {
            this.$root.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    text-align: left;
    height: 44px;
    font-size: 20px;
    padding: 0 20px;
    border-radius: 5px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }
}

.fill-primary {
    background-image: linear-gradient(60deg, rgb(183, 167, 248) 0%, white 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    border: 1px solid rgb(183, 167, 248);

    &:hover {
        transform: scale(1.1);
        transition: ease-in-out 0.3s;
    }
}
</style>
