<!-- ForgetPassword.vue -->
<template>

    <div class="not-authorized-cont">
        <img
          height="70"
          :src="require('@/assets/images/not-authorized.png')"
        >
        <span class="g-font gl-color add-margin" :class="{'dark-gl-color': !darkMode}">Email Not Authorized Yet</span>
        <span class="g-font gl-color add-margin" :class="{'dark-gl-color': !darkMode}">Verification Email has been sent to your account.</span>
        <span class="g-font gl-color add-margin" :class="{'dark-gl-color': !darkMode}">Verify it in 7 days.</span>
        <button type="submit" class="submit-button" @click="goToLogin">Login</button>
    </div>
  </template>
  
  <script>
  import { getMode } from "@/services/localStorage";
  const checkIfDark = (name) => name === "dark-mode";

  export default {
    name: 'notAuthorized',
   
    data() {
        return {
            darkMode: checkIfDark(getMode()),
        }
    },
    created() {
        this.emitter.on("mode-change", (data) => {
        this.darkMode = checkIfDark(data.mode);
        });
    },
    methods: {
        goToLogin() {
        this.$router.push('/signin')
      },
    },
  };
  </script>
  <style scoped>

  .not-authorized-cont {
    width: 25%;
    height: 40%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    margin-top: 20px;
  }


.g-font {
    font-family: "Lato", sans-serif;
 }
 .submit-button {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 45%;
  font-size: 1em;
  border-radius: 4px;
  background-color: #242460;
  margin-top: 20px;
}
.add-margin {
    margin-top: 15px;
}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}

  </style>
  