<template>
    <input :id="inputId" :type="type" :value="value" :class="[inputClass]" :placeholder="label"
        @input="$emit('update:value', $event.target.value)" />
</template>

<script>
export default {
    name: "G-Input",
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        isLabel: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "Input Label",
        },
        inputId: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        value: {
            type: String,
            default: "",
        },
        labelClass: {
            type: String,
            default: "",
        },
        inputClass: {
            type: String,
            default: "",
        },
        errorMsg: {
            type: String,
            default: "",
        },
        imgSrc: {
            type: String,
            default: "",
        },
        imgBGClass: {
            type: String,
            default: "",
        },
        imgClass: {
            type: String,
            default: "",
        },
        url: {
            type: String,
            default: "",
        },
    },
    methods: {
        handleInput(event) {
            this.$emit("change", event.target.value);
        },
    },
};
</script>

<style lang="scss" scoped>
input {
    padding: 10px;
    border: none;
    width: 280px;
    border-radius: 3px;

    &::placeholder {
        color: var(--input-placeholder);
        opacity: 0.3;
    }

    &:focus {
        outline: none;
        /* Remove default focus outline */
        border-color: white;
    }
}
</style>
