<template>
  <div class="table-parent">
    <div class="inner-box">
      <DynamicTable
        @pagination="paginate"
        @filter="fetchData"
        @refresh="refresh"
        :showFilters="true"
        :isLoading="loading"
        :pagination="sortPaginate"
        :columns="tableColumns"
        :data="tableData"
      />
    </div>
    <div v-if="errorMsg" class="error-msg on-center">{{ errorMsg }}</div>
  </div>
</template>

<script>
import DynamicTable from "@/components/Table.vue";
import { get, setHttpParams } from "@/services/http";
import { EMAIL, RETRY_EMAIL } from "@/utils/api-routes";

export default {
  name: "UserList",
  components: {
    DynamicTable,
  },
  data() {
    return {
      tableColumns: [
        {
          key: "created_at",
          label: "Created At",
          type: "date",
          sortable: true,
        },
        { key: "username", label: "Username" },
        { key: "platform", label: "Platform" },
        { key: "amount", label: "Amount" },
        { key: "status", label: "Status", type: "status" },
      ],
      tableData: [],
      sortPaginate: {
        limit: 10,
        start: 1,
        totalPages: 1,
        status: "",
        attribute: "",
        order_by: "",
        search: "",
        field: "",
        total: 0,
      },
      loading: false,
      retrying: false,
      errorMsg: "",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData(filter) {
      try {
        this.loading = true;
        this.errorMsg = "";
        const { data, total_rows: total } = await get(
          EMAIL +
            setHttpParams(this.sortPaginate) +
            `${filter ? "&status=" + filter : ""}&unique_id=${
              this.$route.params.key
            }`
        );
        this.tableData = data;
        this.sortPaginate.total = total;
        this.sortPaginate.totalPages = Math.ceil(
          total / this.sortPaginate.limit
        );
        this.loading = false;
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    },
    async refresh({ email_id }) {
      try {
        if (!this.retrying) {
          this.retrying = true;
          await get(RETRY_EMAIL + `?email_id=${email_id}`);
          this.fetchData();
          this.retrying = false;
        }
      } catch (error) {
        this.errorMsg =
          "* " + error.detail ? error.detail : "Something went wrong";
        console.error("Error retring failed status:", error);
      }
    },
    paginate(data) {
      this.sortPaginate = data;
      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss">
.table-parent {
  overflow: auto;
}
.error-msg {
  color: rgb(243, 107, 107);
  font-size: 1.3em;
  margin: 5px 20px;
}
.inner-box {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  min-width: 800px;
}
</style>
